import React from "react";
import i18n from "../../i18n";

const LOADING_TEXT_INTERVAL = 3000;

const texts = [
  i18n.t("processing_text_0"),
  i18n.t("processing_text_1"),
  i18n.t("processing_text_2"),
  i18n.t("processing_text_3"),
  i18n.t("processing_text_4"),
  i18n.t("processing_text_5"),
];

export default class Loader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textIndex: Math.floor(Math.random() * (texts.length - 1)),
    };

    this.updateTextIndexTimer = null;
  }

  componentDidMount() {
    this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex = () => {
    if (this.state.textIndex === texts.length - 1) {
      this.setState({textIndex: 0});
    } else {
      this.setState({textIndex: this.state.textIndex + 1});
    }
  };

  render() {
    const text = texts[this.state.textIndex];

    return <LoaderView hidden={this.props.hidden} message={text} />
  }
}

function LoaderView({hidden, message}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <div className="loader-container android">
    <div>
      <p dangerouslySetInnerHTML={{__html: message}} />
      <div className="loader-android-2"><div className="loaderBar" /></div>
    </div>
  </div>;
}