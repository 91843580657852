export const hits = {
  CREATIVE_PROCESSED: 0,
  CREATIVE_FAILED: 0,
  CREATIVE_FAILED_NONPHOTOLAB: 0,
};

export function hitEvent(id) {
  if (window.appConfig.isDebug) {
    const hitName = Object.keys(hits).find((key) => hits[key] === id) || "(unknown)";
    console.info("hitEvent", id, hitName);
  }

  if (window.appConfig.hits.isEnabled) {
    const url = `${window.appConfig.hits.endpoint}?id=${id}&r=${Date.now()}`;

    if (window.navigator.sendBeacon) {
      window.navigator.sendBeacon(url);
    } else {
      window.axios.post(url).then(() => {/* dummy */}).catch(console.error);
    }
  }
}