module.exports = {
  "strings": {
    "create_page__select_photo_button": "Upload a photo",
    "create_page__title": "Turn your <span>photo</span> to a cool <span>artwork</span>!",
    "create_page__text": "Soon you will taste a delicious dish, and while you're waiting for your order, have fun and cheer yourself up. Upload your photo and turn it into a real masterpiece of art in just a couple of clicks!",

    "link_guru": "by Restaurant Guru ",

    "btn_download": "download",
    "btn_upload": "Upload another photo",
    "btn_copy": "Copy",
    "link_copied": "Copied",

    "result_page__note": "Use a full-face portrait photo",

    "modal_title": "Share  this link with friends",

    "loader_processing": "Processing",
    "processing_text_0": "Enhancing colors and taste...",
    "processing_text_1": "Applying soy sauce and filters...",
    "processing_text_2": "Adding rice, nori and effects...",
    "processing_text_3": "Preparing your sushi and photo...",
    "processing_text_4": "Putting the last touches...",
    "processing_text_5": "Cooking and enhancing...",

    "photo_capture_page__button_capture": "Take a photo",
    "photo_capture_page__button_retake": "Retake photo",
    "photo_capture_page__button_apply": "Done",
    "photo_capture_page__notify": "How to take the right photo:",
    "photo_capture_page__button_try_again": "Try again",

    "key_photo_do_nots": "KEY PHOTO <span>DON'T</span>s:",
    "no_glasses_on": "<span>NO</span> sunglasses on",
    "face_is_not_covered": "face is <span>NOT</span> covered",
    "no_side_view_selfies": "<span>NO</span> side-view selfies",
    "no_multi_face_photos": "<span>NO</span> multi-face photos",
    "good_lignting": "provide <span>enough</span> lighting",

    "instagram_modal_step_1": "1) Press firmly and swipe up (3D touch) or long tap (iPhone 6 or older) the image to bring up the context menu and select 'Save image'",
    "instagram_modal_step_2": "2) Open the Photos app and select the saved image",
    "instagram_modal_step_3": "3) Tap the Share button in the Photos app and select Instagram from the app list",

    "error_modal__default_message": "Something went wrong. Try again.",
    "error_modal__network_message": "Please check your network connection.",
    "error_modal__camera_message": "Couldn’t take photo, please choose a photo from gallery",

    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "The selected file is in an invalid format",

  },
};
