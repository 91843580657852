import React from "react";
import i18n from "../../i18n";
import FileChooseButton from "../components/FileChooseButton";
import {PhotolabResponseError} from "../../photolab/api";
import {PhotoCaptureError} from "../pages/PhotoCapturePage";

function ErrorView({error, onFileSelected}) {
  let errorMessage = i18n.t("error_modal__default_message");
  if (error instanceof PhotolabResponseError || error instanceof PhotoCaptureError) {
    errorMessage = error.message;
  } else if (error.name === "NetworkError" || error.message === "Network Error") {
    errorMessage = i18n.t("error_modal__network_message");
  }

  return <div className="error-container">
    <div className="container">
      <p dangerouslySetInnerHTML={{__html: errorMessage}} />
      <FileChooseButton
        onFileSelected={onFileSelected}
        className="btn-upload">
        <span dangerouslySetInnerHTML={{__html: i18n.t("btn_upload")}} />
      </FileChooseButton>
    </div>
  </div>;
}

export default ErrorView;