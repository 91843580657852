import Modal from "./Modal";
import React from "react";
import i18n from "../../i18n";
import AppContext from "../../contexts/AppContext";

export default class InstagramModal extends Modal {

  constructor(props) {
    super(props);
    this.className = ""; // todo
  }

  renderModal() {
    return <div className="modal-container insta-modal-container">
      <div className="modal">
        <button className="btn-close" onClick={() => this.dismiss()} />
        <div className="social-container">
          <p>{i18n.t("instagram_modal_step_1")}</p>
          <img src={this.props.file.url} alt="." />
          <p>{i18n.t("instagram_modal_step_2")}</p>
          <p>{i18n.t("instagram_modal_step_3")}</p>
        </div>
      </div>
    </div>
  }
}

InstagramModal.contextType = AppContext;
