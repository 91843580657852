import React from "react";

function getInitialNativeAdState() {
  return {
    isShown: false,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };
}

const AppContext = React.createContext({
  installedApps: {},
  modals: [],
  nativeAd: getInitialNativeAdState(),
});

const AppContextConsumer = AppContext.Consumer;

class AppContextProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      installedApps: {},
      modals: [],
      nativeAd: getInitialNativeAdState(),
    };
  }

  render() {
    const state = {
      ...this.state,
      setInstalledApps: (apps) => {
        this.setState({installedApps: apps});
      },
      pushModal: (component, cb) => {
        const modals = this.state.modals.slice();
        modals.push(component);

        this.setState({modals}, () => cb && cb());
      },
      popModal: (cb) => {
        const modals = this.state.modals.slice();
        modals.pop();

        this.setState({modals}, () => cb && cb());
      },
      setNativeAdState: (isShown, x, y, width, height) => {
        this.setState({
          nativeAd: {
            isShown: !!isShown,
            x,
            y,
            width,
            height,
          },
        });
      },
      hideNativeAd: () => {
        this.setState({
          nativeAd: getInitialNativeAdState(),
        })
      },
    };

    return <AppContext.Provider
      value={state}
      children={this.props.children}
    />;
  }
}

export default AppContext;

export {AppContextConsumer, AppContextProvider};
