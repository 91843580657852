import React from "react";
import Slider from "react-slick";
import i18n from "../../i18n";
import routes from "../../routes";
import AppContext from "../../contexts/AppContext";
import Loader from "../components/Loader";
import FileChooseButton from "../components/FileChooseButton";
import {assetUrl} from "../../utils/etc";
import {logEvent, userEvents} from "../../utils/log";
import {generatePath} from "react-router";

export default class IndexPage extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    logEvent(userEvents.PAGE_INDEX);

    const hash = (new URL(window.location.href)).searchParams.get("result");

    if (hash) {
      this.props.history.replace({
        pathname: generatePath(routes.RESULT, {hash}),
        search: window.location.search,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleFileSelected = (file) => {
    logEvent(userEvents.PHOTO_SELECT, {
      page: "index",
    });

    this.setState({
      isLoading: true,
    }, () => {
      this.props.history.replace({
        pathname: routes.PROCESSING,
        search: window.location.search,
        state: {file: file}
      });
    });
  };

  handlePhotoCaptureClick = () => {
    this.props.history.replace({
      pathname: routes.PHOTO_CAPTURE,
      search: window.location.search,
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader message={i18n.t("loader_processing")} />;
    }

    const sliderSettings = {
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    const isHiddenPhotoCaptureBtn = window.clientConfig.isWebDesktop
        || !window.navigator.mediaDevices
        || !window.navigator.mediaDevices.getUserMedia;

    return <section className="create-page">
      <div className="container">
        <h1 className="section-title" dangerouslySetInnerHTML={{__html: i18n.t("create_page__title")}} />

        <Slider className="create-page-slider" {...sliderSettings}>
          <SlideView name={"slide0"}/>
          <SlideView name={"slide1"} />
          <SlideView name={"slide3"} />
          <SlideView name={"slide4"} />
          <SlideView name={"slide5"} />
        </Slider>

        <div className="btn-container">
          <button
            hidden={isHiddenPhotoCaptureBtn}
            className="btn-crop-photo"
            onClick={this.handlePhotoCaptureClick}>
            <SvgPhotoIcon />
          </button>

          <FileChooseButton
            onFileSelected={this.handleFileSelected}
            className="btn-select-photo">
            <SvgPictureIcon />
            <span dangerouslySetInnerHTML={{__html: i18n.t("create_page__select_photo_button")}} />
          </FileChooseButton>
        </div>

        <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__text")}} />
      </div>

      <img className="icon-bamboo" src={assetUrl(`assets/images/icon-bamboo.png`)} alt="" />
    </section>
  }
}

IndexPage.contextType = AppContext;

function SlideView({name}) {
  const image = [
    assetUrl(`assets/images/${name}.png`)
  ].join(", ");

  return <div className="slide-container">
    <div className="slide">
      <picture>
        <img srcSet={image} alt="Preview" />
      </picture>
    </div>
  </div>;
}

function SvgPhotoIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 525 525">
    <g>
      <path d="M262.5,210.1c-43.4,0-78.6,35.2-78.6,78.6c0,43.4,35.2,78.6,78.6,78.6c43.4,0,78.6-35.2,78.6-78.6
        C341.1,245.3,305.9,210.1,262.5,210.1z M472.1,131.5h-62.9c-8.6,0-18-6.7-20.7-14.9l-16.3-48.8c-2.7-8.2-12-14.9-20.7-14.9H173.4
        c-8.6,0-18,6.7-20.7,14.9l-16.3,48.8c-2.7,8.2-12,14.9-20.7,14.9H52.9c-28.8,0-52.4,23.6-52.4,52.4v235.8
        c0,28.8,23.6,52.4,52.4,52.4h419.2c28.8,0,52.4-23.6,52.4-52.4V183.9C524.5,155.1,500.9,131.5,472.1,131.5z M262.5,419.7
        c-72.3,0-131-58.7-131-131c0-72.3,58.7-131,131-131c72.3,0,131,58.7,131,131C393.5,361,334.8,419.7,262.5,419.7z M453.8,220.5
        c-10.1,0-18.3-8.2-18.3-18.3c0-10.1,8.2-18.3,18.3-18.3c10.1,0,18.3,8.2,18.3,18.3C472.1,212.3,463.9,220.5,453.8,220.5z"/>
    </g>
  </svg>;
}

function SvgPictureIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 525 525">
    <path d="M483.7,56.3H41.3C18.5,56.3,0,74.7,0,97.6v329.9c0,22.8,18.5,41.3,41.3,41.3h442.4c22.8,0,41.3-18.5,41.3-41.3V97.6C525,74.7,506.5,56.3,483.7,56.3z M150,131.3c20.7,0,37.5,16.8,37.5,37.5s-16.8,37.5-37.5,37.5s-37.5-16.8-37.5-37.5S129.3,131.3,150,131.3z M448.1,383.3c-3.2,6.4-9.7,10.5-16.9,10.5H93.8c-7.2,0-13.8-4.2-16.9-10.7c-2.7-6.2-1.8-13.4,2.2-18.8	l75-93.8c6.5-8.1,18.2-9.4,26.3-3c0.2,0.2,0.4,0.4,0.7,0.5l11.9,10.4c9.9,8.7,25.2,7.1,33-3.6l58.9-80c3.5-4.7,9.1-7.5,15-7.5c5.9,0,11.4,2.7,15,7.3l131.3,168.8C450.6,369.2,451.3,376.9,448.1,383.3z"/>
  </svg>;
}
