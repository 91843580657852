import Modal from "./Modal";
import React from "react";
import i18n from "../../i18n";
import AppContext from "../../contexts/AppContext";
import {openWindow} from "../../utils/window";
import {copyTextFromElement} from "../../utils/text";
import InstagramModal from "./InstagramModal";

function fileUrlEncode(file) {
  const filename = file.url.replace("https://pw.restaurantguru.com/photoshare/", "");

  return new Buffer(filename).toString("base64");
}

export default class ShareModal extends Modal {
  state = {
    isLinkCopied: false,
  };

  constructor(props) {
    super(props);
    this.className = ""; // todo
    this.linkFieldRef = React.createRef();
  }

  handleTelegramShareClick = () => {
    openWindow(
      `https://telegram.me/share/url?url=${encodeURIComponent(this.props.shareUrl)}`,
      "_blank"
    );
  };

  handleFacebookShareClick = () => {
    const file = this.props.files.find((file) => file.alias === "facebook");

    const url = new URL(this.props.shareUrl);
    url.searchParams.append("image", fileUrlEncode(file));
    url.searchParams.append("result", this.props.hash);

    openWindow(
      `https://www.facebook.com/dialog/share?app_id=${window.appConfig.facebook.appId}&display=popup&href=${encodeURIComponent(url.toString())}`,
      "_blank"
    );
  };

  handleTwitterShareClick = () => {
    const file = this.props.files.find((file) => file.alias === "facebook");

    const url = new URL(this.props.shareUrl);
    url.searchParams.append("image", fileUrlEncode(file));
    url.searchParams.append("result", this.props.hash);

    openWindow(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(url.toString())}`,
    "_blank"
    );
  };

  handleVKShareClick = () => {
    openWindow(
      `https://vk.com/share.php?url=${encodeURIComponent(this.props.shareUrl)}`,
      "_blank"
    );
  };

  handleInstagramShareClick = () => {
    const file = this.props.files.find((file) => file.alias === "instagram");

    this.context.pushModal(<InstagramModal
        key="ShareModal_InstagramModal"
        shareUrl={this.props.shareUrl}
        file={file}
    />);
  };

  handleNativeShareClick = () => {
    window.navigator.share({url: this.props.shareUrl})
      .then(() => {/* omit */})
      .catch((err) => {
        if (err.name !== "AbortError") {
          this.dismiss();
        }
      });
  };

  handleLinkCopyClick = (e) => {
    e.preventDefault();

    if (this.linkFieldRef.current && copyTextFromElement(this.linkFieldRef.current)) {
      this.setState({
        isLinkCopied: true
      });
    }
  };

  renderModal() {
    const isHiddenNativeShare = window.clientConfig.isWebMobile === false
      || window.navigator.share === undefined;

    const copyUrl = new URL(this.props.copyUrl || this.props.shareUrl);
    copyUrl.searchParams.append("rg_result", this.props.hash);

    return <div className="modal-container">
      <div className="modal">
        <button className="btn-close" onClick={() => this.dismiss()} />
        <h2 className="modal-title" children={i18n.t('modal_title')} />
        <div className="social-container">
          <button className="btn-social" onClick={this.handleInstagramShareClick}>
            <SvgInstaIcon />
          </button>
          <button className="btn-social" onClick={this.handleTelegramShareClick} hidden={true}>
            <SvgTgIcon />
          </button>
          <button className="btn-social" onClick={this.handleFacebookShareClick}>
            <SvgFbIcon />
          </button>
          <button className="btn-social" onClick={this.handleTwitterShareClick}>
            <SvgTwIcon />
          </button>
          <button className="btn-social" onClick={this.handleVKShareClick} hidden={true}>
            <SvgVkIcon />
          </button>
          <button hidden={isHiddenNativeShare} className="btn-social btn-social-share" onClick={this.handleNativeShareClick}>
            <SvgShareIcon />
          </button>
        </div>
        <p className="copy-mention" hidden={!this.state.isLinkCopied}>{i18n.t("link_copied")}</p>
        <form hidden={this.state.isLinkCopied}>
          <input type="text" ref={this.linkFieldRef} readOnly defaultValue={copyUrl.toString()} />
          <button dangerouslySetInnerHTML={{__html: i18n.t("btn_copy")}} onClick={this.handleLinkCopyClick} />
        </form>
      </div>
    </div>
  }
}

function SvgShareIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 525.2 525.2">
    <defs>
      <linearGradient id="gradient_3" gradientUnits="userSpaceOnUse" x1="172.8803" y1="444.0393" x2="535.2589" y2="81.6606">
        <stop offset="0" stopColor="#1C2838"/>
        <stop offset="1" stopColor="#395470"/>
      </linearGradient>
    </defs>
    <path
        fill="url(#gradient_3)"
        d="M420.7,371.2c-20,0-37.9,7.9-51.6,20.2l-188-109.4c1.4-6.1,2.4-12.2,2.4-18.5c0-6.3-1.1-12.4-2.4-18.5
         L367,136.8c14.2,13.1,32.9,21.3,53.7,21.3c43.8,0,79.1-35.3,79.1-79.1c0-43.7-35.3-79.1-79.1-79.1c-43.7,0-79.1,35.3-79.1,79.1
         c0,6.4,1.1,12.4,2.4,18.5L158.2,205.9c-14.2-13.2-32.9-21.4-53.7-21.4c-43.8,0-79.1,35.3-79.1,79.1s35.3,79.1,79.1,79.1
         c20.8,0,39.5-8.2,53.7-21.3L345.8,431c-1.3,5.6-2.1,11.4-2.1,17.2c0,42.5,34.6,77,77,77c42.4,0,77-34.5,77-77
         S463.2,371.2,420.7,371.2z"/>
  </svg>;
}

function SvgInstaIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 64 64">
    <radialGradient id="SVGID_1_" cx="-19616.6172" cy="19940.2656" r="197.06" gradientTransform="matrix(-0.75 0 0 0.75 -14713.75 -14892.499)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#F9ED32"/>
      <stop offset="0.36" stopColor="#EE2A7B"/>
      <stop offset="0.44" stopColor="#D22A8A"/>
      <stop offset="0.6" stopColor="#8B2AB2"/>
      <stop offset="0.83" stopColor="#1B2AF0"/>
      <stop offset="0.88" stopColor="#002AFF"/>
    </radialGradient>
    <path fill="url(#SVGID_1_)" d="M52.8,64H11.2C5,64,0,59,0,52.8V11.2C0,5,5,0,11.2,0h41.6C59,0,64,5,64,11.2v41.6C64,59,59,64,52.8,64z"/>
    <path fill="#FFF" d="M44,56H20c-6.6,0-12-5.4-12-12V20c0-6.6,5.4-12,12-12h24c6.6,0,12,5.4,12,12v24C56,50.6,50.6,56,44,56z
        M20,12.8c-4,0-7.2,3.2-7.2,7.2v24c0,4,3.2,7.2,7.2,7.2h24c4,0,7.2-3.2,7.2-7.2V20c0-4-3.2-7.2-7.2-7.2H20z"/>
    <path fill="#FFF" d="M32,45.6c-7.5,0-13.6-6.1-13.6-13.6c0-7.5,6.1-13.6,13.6-13.6c7.5,0,13.6,6.1,13.6,13.6c0,0,0,0,0,0
        C45.6,39.5,39.5,45.6,32,45.6z M32,23.2c-4.9,0-8.8,3.9-8.8,8.8s3.9,8.8,8.8,8.8s8.8-3.9,8.8-8.8C40.8,27.1,36.9,23.2,32,23.2z"/>
    <circle fill="#FFF" cx="45.6" cy="19.2" r="2.4"/>
  </svg>;
}

function SvgTgIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1"  viewBox="0 0 64 64">
    <g>
        <path fill="#04ACDE" d="M11.2,0h41.6C59,0,64,5,64,11.2v41.6C64,59,59,64,52.8,64H11.2C5,64,0,59,0,52.8V11.2C0,5,5,0,11.2,0z"/>
        <g>
            <polygon fill="#A8CAE1" points="27.4,46.1 35.2,39.6 28.4,36.2 	"/>
            <path fill="#FFFFFF" d="M49.5,16.3L13.9,29.6c-0.5,0.2-0.7,0.7-0.5,1.2c0.1,0.3,0.3,0.5,0.6,0.6l12.8,3.7c0.1,0,0.2,0.1,0.3,0.2
                l16,12.3c0.4,0.3,1,0.2,1.3-0.2c0.1-0.1,0.1-0.2,0.2-0.4l6.2-29.6c0.1-0.5-0.1-1-0.6-1.2C49.9,16.2,49.7,16.2,49.5,16.3z"/>
            <polygon fill="#C1D8E9" points="23.7,34.1 27.4,46.1 28.4,36.2 45.3,21.1 	"/>
        </g>
    </g>
  </svg>;
}

function SvgFbIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1"  viewBox="0 0 64 64">
    <g>
      <path fill="#385997" d="M11.2,0h41.6C59,0,64,5,64,11.2v41.6C64,59,59,64,52.8,64H11.2C5,64,0,59,0,52.8V11.2C0,5,5,0,11.2,0z"/>
      <path fill="#FFF" d="M54.4,29.6h-10V22c0-1.9,2.1-2,2.8-2H54V9.6h-6.8C39.7,9.6,34,14.9,34,22v7.6h-8.4V40H34v24h10.4V40h8.4L54.4,29.6z"/>
    </g>
  </svg>;
}

function SvgTwIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1"  viewBox="0 0 64 64">
    <g>
      <path fill="#00AADE" d="M52.8,64H11.2C5,64,0,59,0,52.8V11.2C0,5,5,0,11.2,0h41.6C59,0,64,5,64,11.2v41.6C64,59,59,64,52.8,64z"/>
      <path fill="#FFFFFF" d="M47.8,21c1.9-1,3.4-2.7,4-4.8c-1.6,1.6-5,2.1-5,2.1c-2.8-3.4-7.8-3.9-11.3-1.2C33,19.2,32,22.6,33,25.8h-0.4C26,25.3,19.9,22.1,15.8,17c0,0-4.8,6.4,2.4,11.2c0,0-3.2,0-4-0.8c0,0-0.8,4.8,6.4,8c-1,0.5-2.2,0.5-3.2,0c0,3.1,3.2,5.6,7.2,5.6c0,2.2-5.4,4-12,4c10.4,7.2,35.2,5.6,36.8-21.6l4-4L47.8,21z"/>
    </g>
  </svg>;
}

function SvgVkIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1"  viewBox="0 0 64 64">
    <g>
      <path fill="#4489C8" d="M11.2,0h41.6C59,0,64,5,64,11.2v41.6C64,59,59,64,52.8,64H11.2C5,64,0,59,0,52.8V11.2C0,5,5,0,11.2,0z"/>
      <path fill="#FFF" d="M9.6,19.8h7.7c0.3,0,0.6,0.2,0.7,0.5c0.9,2.2,4.4,10.8,7.6,11.5c2.4,0,1.5-11.4-1.5-11.4c-0.8,0,1.4-1.4,1.6-1.5c2.8-0.8,5.8-0.8,8.7,0c1.5,0.6,1.8,2.4,1.8,4s-1.7,8,0.6,8.7c3,0.9,6.9-8.5,7.8-10.8c0.1-0.3,0.4-0.5,0.7-0.5h8.5c0.4,0,0.8,0.4,0.8,0.8c0,0.1,0,0.2-0.1,0.3c-1.8,4-3.9,7.9-6.3,11.5c-0.4,0.6-0.4,1.5,0.2,2C51,37.6,58,45.3,54,45.3h-8.6c-0.5,0-0.9-0.2-1.2-0.5c-1.3-1.5-5.2-5.6-7-5.3c-1.5,0.3-1.6,2.8-1.5,4.4c0,0.9-0.6,1.6-1.5,1.7c-1.5,0.1-3.6,0.1-3.8,0.1c-6.1-0.4-9.7-4.5-13.2-9c-3.6-4.8-6.4-10.2-8.4-15.9c-0.1-0.4,0.1-0.9,0.5-1C9.5,19.8,9.5,19.8,9.6,19.8z"/>
    </g>
  </svg>;
}

ShareModal.contextType = AppContext;
