import React from "react";
import AppContext from "../../contexts/AppContext";
import i18n from "../../i18n";
import Loader from "../components/Loader";
import ImageView from "../components/ImageView";
import {logEvent, userEvents} from "../../utils/log";
import FileChooseButton from "../components/FileChooseButton";
import ShareModal from "../modals/ShareModal";
import routes from "../../routes";
import * as api from "../../utils/api";

export default class ResultPage extends React.Component {
  state = {
    isLoading: true,
    files: [],
    error: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.files) {
      this.setState({
        isLoading: false,
        files: this.props.location.state.files,
      });
    } else {
      api.fetchCreative(this.props.match.params.hash).then((creative) => {
        this.setState({
          isLoading: false,
          files: creative.files,
        });
      }).catch((error) => {
        this.setState({
          isLoading: false,
          error,
        });
      });
    }
  }

  handleFileSelected = (file) => {
    this.setState({
      isLoading: true,
    }, () => {
      this.props.history.replace({
        pathname: routes.PROCESSING,
        search: window.location.search,
        state: {file: file}
      });
    });
  };

  showShareModal = () => {
    const url = new URL(window.location.href);
    const shareUrl = url.searchParams.get("share_url") || "";
    const copyUrl = url.searchParams.get("copy_url") || "";
    const title = url.searchParams.get("title") || "";
    const description = url.searchParams.get("nomination") || "";

    if (!shareUrl) {
      return;
    }

    this.context.pushModal(<ShareModal
      key="ResultPage_ShareModal"
      shareUrl={shareUrl}
      copyUrl={copyUrl}
      title={title}
      description={description}
      files={this.state.files}
      hash={this.props.match.params.hash}
    />);
  }

  handleDownloadClick = () => {
    logEvent(userEvents.RESULT_DOWNLOAD);

    this.showShareModal();
  };

  handleImageLoaded = () => {
    logEvent(userEvents.RESULT_LOADED);
  };

  render() {
    if (this.state.isLoading) {
      return <Loader message={i18n.t("loader_processing")} />;
    }

    return <section className="result-page">
      <div className="collage-container">
        <ResultView
          files={this.state.files}
          onImageLoaded={this.handleImageLoaded}
          onDownloadClick={this.handleDownloadClick}
          onShareClick={this.showShareModal}
          onFileSelected={this.handleFileSelected}
        />
      </div>
    </section>;
  }
}

ResultPage.contextType = AppContext;

function ResultView({files, onImageLoaded, onDownloadClick, onShareClick, onFileSelected}) {
  const baseFile = files.find((file) => file.alias === "base");

  const downloadUrl = new URL(window.appConfig.paths.api);
  downloadUrl.pathname = "/api/download";
  downloadUrl.searchParams.append("content_url", baseFile.url);

  return <React.Fragment>
    <div className="container">
      <ImageView image={baseFile} onImageLoaded={onImageLoaded}/>
      <div className="footer-container">
        <a
          href={downloadUrl.toString()}
          download
          rel="noopener noreferrer"
          className="btn-download"
          onClick={onDownloadClick}>
          <SvgDownloadIcon />
          <span dangerouslySetInnerHTML={{__html: i18n.t("btn_download")}} />
        </a>
        <div className="btn-container">
          <FileChooseButton className="btn-upload" onFileSelected={onFileSelected}>
            <SvgPhotoIcon />
            <span dangerouslySetInnerHTML={{__html: i18n.t("btn_upload")}} />
          </FileChooseButton>
          <button className="btn-share" onClick={onShareClick}>
            <SvgShareIcon />
          </button>
        </div>
      </div>
    </div>
  </React.Fragment>;
}

function SvgShareIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 525.2 525.2">
    <defs>
      <linearGradient id="gradient_2" gradientUnits="userSpaceOnUse" x1="172.8803" y1="444.0393" x2="535.2589" y2="81.6606">
        <stop offset="0" stopColor="#1C2838"/>
        <stop offset="1" stopColor="#395470"/>
      </linearGradient>
    </defs>
    <path
        fill="url(#gradient_2)"
        d="M420.7,371.2c-20,0-37.9,7.9-51.6,20.2l-188-109.4c1.4-6.1,2.4-12.2,2.4-18.5c0-6.3-1.1-12.4-2.4-18.5
         L367,136.8c14.2,13.1,32.9,21.3,53.7,21.3c43.8,0,79.1-35.3,79.1-79.1c0-43.7-35.3-79.1-79.1-79.1c-43.7,0-79.1,35.3-79.1,79.1
         c0,6.4,1.1,12.4,2.4,18.5L158.2,205.9c-14.2-13.2-32.9-21.4-53.7-21.4c-43.8,0-79.1,35.3-79.1,79.1s35.3,79.1,79.1,79.1
         c20.8,0,39.5-8.2,53.7-21.3L345.8,431c-1.3,5.6-2.1,11.4-2.1,17.2c0,42.5,34.6,77,77,77c42.4,0,77-34.5,77-77
         S463.2,371.2,420.7,371.2z"/>
  </svg>;
}

function SvgPhotoIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 525 525">
    <defs>
      <linearGradient id="gradient_1" gradientUnits="userSpaceOnUse" x1="0.5" y1="262.5" x2="524.5" y2="262.5">
        <stop offset="0" stopColor="#1C2838"/>
        <stop offset="1" stopColor="#395470"/>
      </linearGradient>
    </defs>
    <path
      fill="url(#gradient_1)"
      d="M262.5,210.1c-43.4,0-78.6,35.2-78.6,78.6c0,43.4,35.2,78.6,78.6,78.6c43.4,0,78.6-35.2,78.6-78.6
         C341.1,245.3,305.9,210.1,262.5,210.1z M472.1,131.5h-62.9c-8.6,0-18-6.7-20.7-14.9l-16.3-48.8c-2.7-8.2-12-14.9-20.7-14.9H173.4
         c-8.6,0-18,6.7-20.7,14.9l-16.3,48.8c-2.7,8.2-12,14.9-20.7,14.9H52.9c-28.8,0-52.4,23.6-52.4,52.4v235.8
         c0,28.8,23.6,52.4,52.4,52.4h419.2c28.8,0,52.4-23.6,52.4-52.4V183.9C524.5,155.1,500.9,131.5,472.1,131.5z M262.5,419.7
         c-72.3,0-131-58.7-131-131c0-72.3,58.7-131,131-131c72.3,0,131,58.7,131,131C393.5,361,334.8,419.7,262.5,419.7z M453.8,220.5
         c-10.1,0-18.3-8.2-18.3-18.3c0-10.1,8.2-18.3,18.3-18.3c10.1,0,18.3,8.2,18.3,18.3C472.1,212.3,463.9,220.5,453.8,220.5z" />
  </svg>;
}

function SvgDownloadIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 525 525">
    <path d="M468.5,261c0-0.1,0-0.2,0-0.3c0-5.6-1.8-10.1-5.4-13.7c-3.3-3.3-7.4-5-12.4-5.3c-0.3,0-0.6,0-0.9,0c-0.1,0-0.3,0-0.4,0h-0.2c-0.1,0-0.2,0-0.3,0H339.1V19.1c0-5.6-1.8-10.1-5.4-13.7C330.2,1.8,325.6,0,320,0h-0.5H205.5H205c-5.6,0-10.1,1.8-13.7,5.4c-3.6,3.6-5.4,8.2-5.4,13.7v222.5H76c-0.1,0-0.2,0-0.3,0h-0.2c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6,0-0.9,0c-5,0.3-9.1,2-12.4,5.3c-3.6,3.6-5.4,8.2-5.4,13.7c0,0.1,0,0.2,0,0.3c0,3.4,0.9,6.7,3,9.8c0.8,1.4,1.8,2.8,3,4.2l186.1,244.6c4.4,3.6,9,5.4,13.7,5.4c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0c4.8,0,9.3-1.8,13.7-5.4L462.6,275c1.2-1.4,2.1-2.8,3-4.2C467.6,267.7,468.6,264.4,468.5,261z"/>
  </svg>;
}
