import React from "react";
import AppContext from "../../contexts/AppContext";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import {photolabAddTask, PhotolabResponseError, photolabWaitTask} from "../../photolab/api";
import i18n from "../../i18n";
import Loader from "../components/Loader";
import {logEvent, userEvents} from "../../utils/log";
import {hitEvent, hits} from "../../utils/hits";
import * as api from "../../utils/api";
import routes from "../../routes";
import ErrorView from "../components/ErrorView";
import {generatePath} from "react-router";

class ApiTaskError extends Error {
  constructor(task) {
    super();
    this.name = "ApiTaskError";
    this.code = -1;
    this.message = task.result && task.result.reason;
  }
}

function waitTask(taskId, timeout = 0, interval = 1000, requestsAmount = 0) {
  function _call(resolve, reject) {
    api.fetchTask(taskId).then((task) => {
      if (task.status === 1) {
        resolve(task);
      } else if (task.status === -1) {
        throw new ApiTaskError(task);
      } else {
        setTimeout(() => {
          waitTask(taskId, 0, interval).then(resolve).catch(reject);
        }, interval || 1000);
      }
    }).catch((error) => {
      reject(error);
    });
  }

  return new Promise((resolve, reject) => {
    if (timeout === 0) {
      _call(resolve, reject);
    } else {
      setTimeout(() => _call(resolve, reject), timeout);
    }
  });
}

function getInitProcessing() {
  return {
    tries: 0,
    isProcessing: true,
    isProcessed: false,
    isFailed: false,
  }
}

export default class ProcessingPage extends React.Component {

  state = {
    isLoading: true,
    file: null,
    processing: null,
  };

  constructor(props) {
    super(props);

    if (this.props.location.state && this.props.location.state.file) {
      this.createFile(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentDidMount() {
    logEvent(userEvents.PAGE_PROCESSING);
  }

  handleFileSelected = (file) => {
    logEvent(userEvents.PHOTO_SELECT, {
      page: "processing",
    });

    this.setState({
      isLoading: true,
    }, () => {
      this.createFile(file);
    });
  };

  createFile = (file) => {
    const processing = getInitProcessing();

    api.createFile(file)
      .then((file) => {
        logEvent(userEvents.PHOTO_UPLOADED);

        this.setState({
          file: file,
        }, () => {
          this.startProcessing(processing);
        });
      })
      .catch((err) => {
        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({
          isLoading: false,
          processing: processing,
        });
      });
  };

  startProcessing(processing) {
    (window.parent || window).postMessage("processing-start", "*");

    const url = new URL(window.location.href);
    const title = url.searchParams.get("title") || "";
    const address = url.searchParams.get("address") || "";
    const nomination = url.searchParams.get("nomination") || "";

    function resultConfig(imageUrl) {
      return new PhotolabTaskBuilder()
        .addImage(new PhotolabTaskImageUrl(imageUrl))
        .addMethod(new PhotolabTaskCollageMethod(5718))
        .build();
    }

    photolabAddTask(resultConfig(this.state.file.url))
      .then((taskResult) => photolabWaitTask(taskResult.requestId, 5000, 1000))
      .then((taskResult) => api.enqueueCreativeStoreTask(taskResult.resultUrl, title, address, nomination))
      .then((taskResult) => waitTask(taskResult.id, 1000))
      .then((taskResult) => {
        (window.parent || window).postMessage("processing-complete", "*");
        hitEvent(hits.CREATIVE_PROCESSED);

        processing.isProcessing = false;
        processing.isProcessed = true;
        processing.result = taskResult.result;

        this.props.history.replace({
          pathname: generatePath(routes.RESULT, {hash: taskResult.result.hash}),
          search: window.location.search,
          state: {files: taskResult.result.files}
        });
      })
      .catch((err) => {
        (window.parent || window).postMessage("processing-failed", "*");
        hitEvent(hits.CREATIVE_FAILED);

        const logError = {
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        if (err instanceof PhotolabResponseError) {
          logError.taskRequestId = err.requestId;
          logError.taskInputImageUrl = this.state.file.url;
        } else {
          hitEvent(hits.CREATIVE_FAILED_NONPHOTOLAB);
        }

        logEvent(userEvents.PROCESSING_ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({
          isLoading: false,
          processing: processing,
        });
      });
  }

  render() {
    if (this.state.isLoading) {
      return <Loader message={i18n.t("loader_processing")} />;
    }

    const processing = this.state.processing;

    return <section className="result-page">
      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />
        </div>
        {processing && processing.isFailed && <ErrorView
            error={processing.error}
            onFileSelected={this.handleFileSelected}
        />}
      </div>
    </section>;
  }
}

ProcessingPage.contextType = AppContext;
